import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import demo from '../../../../assets/promo/demoV2.png'
import { Grid } from '@mui/material'

export const PromoPageFFR = () => {

    return (
        <PrivatePage >
            <Grid container>
                <img
                    src={demo}
                    style={{ width: "100%" }}
                    alt="maquettes promo"
                />
            </Grid>
        </PrivatePage>
    )
}
